import { render, staticRenderFns } from "./place-form-step-2.vue?vue&type=template&id=732bbb33&scoped=true&"
import script from "./place-form-step-2.vue?vue&type=script&lang=js&"
export * from "./place-form-step-2.vue?vue&type=script&lang=js&"
import style0 from "./place-form-step-2.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./place-form-step-2.vue?vue&type=style&index=1&id=732bbb33&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732bbb33",
  null
  
)

export default component.exports
import {QForm,QTabs,QTab,QTabPanels,QTabPanel,QInput,QIcon,QBtn,QField,QItem} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QTabs,QTab,QTabPanels,QTabPanel,QInput,QIcon,QBtn,QField,QItem})
