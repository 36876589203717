//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';


export default {
  name: 'app-place-form-step-2',
  props: {
    value: {
      type: Object,
      required: true,
    },
    loading:{
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
        tab: 'en',
        notificationName: '',
        avatar: '',
        notificationLang: [
          {
            value: 'en',
            label: 'English'
          },{
            value: 'ar',
            label: 'Arabic'
          },{
            value: 'fr',
            label: 'French'
          },{
            value: 'es',
            label: 'Spanish'
          },
        ],
      language: getLanguageCode(),
      model: null,
      autocomplete: '',
      
      displayPicker: false,
      displaySPicker: false,
      title: '',
      description: '',
      // notifications: [{
      //   title: undefined,
      //   description: undefined
      // } ,
      // {
      //   title: undefined,
      //   description: undefined
      // }, 
      // {
      //   title: undefined,
      //   description: undefined
      // }, 
      // {
      //   title: undefined,
      //   description: undefined
      // }]
    };
  },

  computed: {
    ...mapGetters({
      record: 'place/form/record',
      findLoading: 'place/form/findLoading',
      saveLoading: 'place/form/saveLoading',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading',  
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isEditing() {
      return !!this.id;
    },
    isRTL() {
      // return localStorage.getItem('language') == 'ar';
      return this.currentLanguageCode == 'ar';
    },
    fields() {
      return fields;
    },
  },


  async created() {
    // this.model = []
    // if (this.record && this.record['notifications']) {
    //   this.notificationLang.forEach(lang => {
    //     this.model.push({
    //       lang: lang.value,
    //       title: this.record.notifications[lang.value] ? this.record.notifications[lang.value].title : null,
    //       message: this.record.notifications[lang.value] ? this.record.notifications[lang.value].message : null,
    //     })
    //   });
    // } else {
    //   this.notificationLang.forEach(lang => {
    //     this.model.push({
    //       lang: lang.value,
    //       title: null,
    //       message: null,
    //     })
    //   });
    // }
  },


  methods: {
    ...mapActions({
      doFind: 'place/form/doFind',
      doNew: 'place/form/doNew',
      doUpdate: 'place/form/doUpdate',
      doCreate: 'place/form/doCreate',
      setPlaces: 'place/form/setPlaces',
      doFetchCities: 'city/list/doFetch',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
   

    // ........................................................................................................................
    // loadControls function specified in create add my current location button and bind all style
    // to this button , and add listener to that button when clicked to call the blew child
    // that call another function called getCurrentPosition that return the current location
    // finally set the position of this button on  RIGHT_BOTTOM as a custom element
    loadControls(map) {
      var controlDiv = document.createElement('div');
      var firstChild = document.createElement('button');
      firstChild.style.backgroundColor = '#fff';
      firstChild.style.border = 'none';
      firstChild.style.outline = 'none';
      firstChild.style.width = '28px';
      firstChild.style.height = '28px';
      firstChild.style.borderRadius = '2px';
      firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
      firstChild.style.cursor = 'pointer';
      firstChild.style.marginRight = '10px';
      firstChild.style.padding = '0px';
      firstChild.title = 'Your Location';
      controlDiv.appendChild(firstChild);
      var secondChild = document.createElement('div');
      secondChild.style.margin = '5px';
      secondChild.style.width = '18px';
      secondChild.style.height = '18px';
      secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
      secondChild.style.backgroundSize = '180px 18px';
      secondChild.style.backgroundPosition = '0px 0px';
      secondChild.style.backgroundRepeat = 'no-repeat';
      secondChild.id = 'you_location_img';
      firstChild.appendChild(secondChild);
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        'center_changed',
        function() {
          secondChild.style['background-position'] = '0 0';
        },
      );
      var ref = this;
      firstChild.addEventListener('click', function() {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let latlng = new window.google.maps.LatLng(
              parseFloat(position.coords.latitude),
              parseFloat(position.coords.longitude),
            );
            ref.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          },
        );
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
    },

    // Re initialize all records (reseted records )
    doReset() {
    },

    // submitting place form ,  first check validation then catch error if exist
    // make an http request to google map that request the city and country with given lat and lang
    // then it returns the city and country to set them into database
    // finally assign this fields { lng , lat , fillColor ,  radius , strokeColor , strokeWidth , city , country  }
    // cast model with this fields
    // call function setPlaces and pass values to it
    // if user is editing then call doUpdate function else call doCreate function
    async doSubmit() {
      this.$emit('submitForm')
    },

    //  setPlcae function take place and assigned it to currentPlace
    //  set address of that place with street , city and country


    // addMarker function check first if CurrentPlace if yes
    // set marker to that place
    // push this place to array places which consist of lists of all places
    // then assign center with lat and lng to center of that marker
    // finally set current place to null value


    // geolocate function call get current postion and set center to that position lat and lng


    // updateCoordinates take location as parameter and set center lat and lng to that location

    // this collection of functions { updateColors , updateSColors , showPicker  , showSPicker }
    // , { hidePicker , hideSPicker , togglePicker , toggleSPicker , updateFromInput }
    // and  { updateFromSInput , updateFromPicker ,  updateFromSPicker , documentClick , documentSClick  }
    // all this functions for color picker and bind it's values and show popup of that picker in responsive form
    // then watch for { colorValue and strokeColor }
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ( (1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) +  parseInt(rgba[2]) ).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    updateSColors(color) {
      if (color.slice(0, 1) == '#') {
        this.Scolors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.Scolors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    showSPicker() {
      document.addEventListener('click', this.documentSClick);
      this.displaySPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    hideSPicker() {
      document.removeEventListener('click', this.documentSClick);
      this.displaySPicker = false;
    },
    togglePicker() {
      this.displayPicker
        ? this.hidePicker()
        : this.showPicker();
    },
    toggleSPicker() {
      this.displaySPicker
        ? this.hideSPicker()
        : this.showSPicker();
    },
    // two
    updateFromSInput() {
      this.updateSColors(this.strokeColor);
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    // end
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    updateFromSPicker(color) {
      this.Scolors = color;
      if (color.rgba.a == 1) {
        this.strokeColor = color.hex;
      } else {
        this.strokeColor = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    documentSClick(e) {
      var el = this.$refs.colorSpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hideSPicker();
      }
    },
  },
  watch: {

  },
};
